export const GET_ALL_SUPPORTED_CURRENCIES_REQUEST = 'GET_ALL_SUPPORTED_CURRENCIES_REQUEST';
export const GET_ALL_SUPPORTED_CURRENCIES_SUCCESS = 'GET_ALL_SUPPORTED_CURRENCIES_SUCCESS';
export const GET_ALL_SUPPORTED_CURRENCIES_FAILED = 'GET_ALL_SUPPORTED_CURRENCIES_FAILED';

export const GET_ALL_CURRENCIES_REQUEST = 'GET_ALL_CURRENCIES_REQUEST';
export const GET_ALL_CURRENCIES_SUCCESS = 'GET_ALL_CURRENCIES_SUCCESS';
export const GET_ALL_CURRENCIES_FAILED = 'GET_ALL_CURRENCIES_FAILED';

export const CREATE_NEW_CURRENCY_REQUEST = 'CREATE_NEW_CURRENCY_REQUEST';
export const CREATE_NEW_CURRENCY_SUCCESS = 'CREATE_NEW_CURRENCY_SUCCESS';
export const CREATE_NEW_CURRENCY_FAILED = 'CREATE_NEW_CURRENCY_FAILED';

export const UPDATE_CURRENCY_REQUEST = 'UPDATE_CURRENCY_REQUEST';
export const UPDATE_CURRENCY_SUCCESS = 'UPDATE_CURRENCY_SUCCESS';
export const UPDATE_CURRENCY_FAILED = 'UPDATE_CURRENCY_FAILED';

export const TOGGLE_IS_ACTIVE_CURRENCY_REQUEST = 'TOGGLE_IS_ACTIVE_CURRENCY_REQUEST';
export const TOGGLE_IS_ACTIVE_CURRENCY_SUCCESS = 'TOGGLE_IS_ACTIVE_CURRENCY_SUCCESS';
export const TOGGLE_IS_ACTIVE_CURRENCY_FAILED = 'TOGGLE_IS_ACTIVE_CURRENCY_FAILED';

export const DELETE_CURRENCY_REQUEST = 'DELETE_CURRENCY_REQUEST';
export const DELETE_CURRENCY_SUCCESS = 'DELETE_CURRENCY_SUCCESS';
export const DELETE_CURRENCY_FAILED = 'DELETE_CURRENCY_FAILED';
