// assets
import {
    IconTypography,
    IconPalette,
    IconHome,
    IconShadow,
    IconWindmill,
    IconUsers,
    IconUserPlus,
    IconPhoto,
    IconServer2,
    IconAlignCenter
} from '@tabler/icons';
import PRIVILEGES from 'env/privileges';
// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill
};

// ==============================|| property MENU ITEMS ||============================== //

const WebsiteContent = {
    id: 'WebsiteContent',
    title: 'Website Content',
    caption: 'Website Content Management',
    privilege: [PRIVILEGES.CONTENT_MANAGEMENT, PRIVILEGES.SERVICE_MANAGEMENT, PRIVILEGES.IMAGE_SLIDER_MANAGEMENT],
    type: 'group',
    children: [
        {
            id: 'general',
            title: 'General',
            type: 'collapse',
            icon: IconAlignCenter,
            privilege: [PRIVILEGES.CONTENT_MANAGEMENT],

            children: [
                {
                    id: 'about-us',
                    title: 'Edit About Us',
                    type: 'item',
                    url: '/about-us/edit',
                    icon: IconAlignCenter,
                    privilege: [PRIVILEGES.CONTENT_MANAGEMENT],

                    breadcrumbs: false
                },
                {
                    id: 'contact_us',
                    title: 'Edit Contact Us',
                    type: 'item',
                    url: '/contact-us/edit',
                    icon: IconAlignCenter,
                    privilege: [PRIVILEGES.CONTENT_MANAGEMENT],

                    breadcrumbs: false
                },
                {
                    id: 'privacy_policy',
                    title: 'Edit Privacy Policy',
                    type: 'item',
                    url: '/privacy-policy/edit',
                    icon: IconAlignCenter,
                    privilege: [PRIVILEGES.CONTENT_MANAGEMENT],

                    breadcrumbs: false
                },
                {
                    id: 'cookie_policy',
                    title: 'Edit Cookie Policy',
                    type: 'item',
                    url: '/cookie-policy/edit',
                    icon: IconAlignCenter,
                    privilege: [PRIVILEGES.CONTENT_MANAGEMENT],

                    breadcrumbs: false
                },
                {
                    id: 'legal_notices_disclaimer',
                    title: 'Edit Legal Notices Disclaimer',
                    type: 'item',
                    url: '/legal-notices-disclaimer/edit',
                    icon: IconAlignCenter,
                    privilege: [PRIVILEGES.CONTENT_MANAGEMENT],

                    breadcrumbs: false
                },
                {
                    id: 'terms_use',
                    title: 'Edit Terms Use',
                    type: 'item',
                    url: '/terms-use/edit',
                    icon: IconAlignCenter,
                    privilege: [PRIVILEGES.CONTENT_MANAGEMENT],

                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'services',
            title: 'Services',
            type: 'collapse',
            privilege: [PRIVILEGES.SERVICE_MANAGEMENT],
            icon: IconServer2,

            children: [
                {
                    id: 'all-services',
                    title: 'All Services',
                    type: 'item',
                    url: '/services',
                    icon: IconServer2,
                    privilege: [PRIVILEGES.SERVICE_MANAGEMENT],
                    breadcrumbs: false
                },
                {
                    id: 'add-service',
                    title: 'Add Service',
                    type: 'item',
                    url: '/services/add',
                    icon: IconServer2,
                    privilege: [PRIVILEGES.SERVICE_MANAGEMENT],
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'image-slider',
            title: 'Image Sliders',
            type: 'collapse',

            icon: IconPhoto,
            privilege: [PRIVILEGES.IMAGE_SLIDER_MANAGEMENT],
            children: [
                {
                    id: 'all-image-sliders',
                    title: 'All Image Sliders',
                    type: 'item',
                    url: '/image-sliders',
                    icon: IconPhoto,
                    privilege: [PRIVILEGES.IMAGE_SLIDER_MANAGEMENT],

                    breadcrumbs: false
                },
                {
                    id: 'add-image-slider',
                    title: 'Add Image Slider',
                    type: 'item',
                    url: '/image-sliders/add',
                    icon: IconPhoto,
                    privilege: [PRIVILEGES.IMAGE_SLIDER_MANAGEMENT],

                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default WebsiteContent;
