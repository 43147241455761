// assets
import { IconTypography, IconPalette, IconHome, IconShadow, IconWindmill, IconUsers, IconUserPlus } from '@tabler/icons';
import PRIVILEGES from 'env/privileges';
// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill
};

// ==============================|| property MENU ITEMS ||============================== //

const property = {
    id: 'property',
    title: 'Property',
    caption: 'Property Management',
    privilege: [
        PRIVILEGES.MAKER,
        PRIVILEGES.CHECKER,
        PRIVILEGES.COPYWRITER,
        PRIVILEGES.PROPERTY_TYPE_MANAGEMENT,
        PRIVILEGES.PROPERTY_STATUS_MANAGEMENT,
        PRIVILEGES.OTHER_PROPERTY_DETAILS_MANAGEMENT,
        PRIVILEGES.OTHER_DETAIL_MANAGEMENT,
        PRIVILEGES.PROPERTY_FEATURES_MANAGEMENT,
        PRIVILEGES.PROPERTY_TAGS_MANAGEMENT
    ],
    type: 'group',
    children: [
        {
            id: 'admin-property-process',
            title: 'Admin Property settings',
            type: 'collapse',
            privilege: ['ANY'],
            icon: IconHome,
            breadcrumbs: false,
            children: [
                {
                    id: 'all-properties',
                    title: 'All Properties ',
                    type: 'item',
                    url: '/all-properties',
                    privilege: ['ANY'],
                    icon: IconHome,
                    breadcrumbs: false
                },
                {
                    id: 'published-properties',
                    title: 'Published Properties ',
                    type: 'item',
                    url: '/published-properties',
                    privilege: ['ANY'],
                    icon: IconHome,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'add-new-property-process',
            title: 'Maker Process ',
            type: 'collapse',
            privilege: [PRIVILEGES.MAKER],
            icon: () => {
                return <i className="fa-solid fa-bars-progress"></i>;
            },
            breadcrumbs: false,
            children: [
                {
                    id: 'add-new-property',
                    title: 'Add New Property ',
                    type: 'item',
                    url: '/AddNewProperty',
                    icon: () => {
                        return <i className="fa-solid fa-bars-progress"></i>;
                    },
                    privilege: [PRIVILEGES.MAKER],
                    breadcrumbs: false
                },

                {
                    id: 'maker-rejected-properties',
                    title: 'Rejected Properties ',
                    type: 'item',
                    url: '/maker-rejected-properties',
                    icon: () => {
                        return <i className="fa-solid fa-bars-progress"></i>;
                    },
                    privilege: [PRIVILEGES.MAKER],
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'copy-write-process',
            title: 'Copy Writ Process ',
            type: 'collapse',
            privilege: [PRIVILEGES.COPYWRITER],
            icon: () => {
                return <i className="fa-solid fa-marker"></i>;
            },
            breadcrumbs: false,
            children: [
                {
                    id: 'properties-need-to-copy-write',
                    title: 'My Task',
                    type: 'item',
                    url: '/properties-need-to-copy-write',
                    icon: () => {
                        return <i className="fa-solid fa-marker"></i>;
                    },
                    privilege: [PRIVILEGES.COPYWRITER],
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'check-process',
            title: 'Check Process ',
            type: 'collapse',
            privilege: [PRIVILEGES.CHECKER],
            icon: () => {
                return <i className="fa-solid fa-check-double"></i>;
            },
            breadcrumbs: false,
            children: [
                {
                    id: 'properties-need-to-check',
                    title: 'My Task ',
                    type: 'item',
                    privilege: [PRIVILEGES.CHECKER],
                    url: '/properties-need-to-check',
                    icon: () => {
                        return <i className="fa-solid fa-check-double"></i>;
                    },
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'property-type',
            title: 'Property Type',
            type: 'collapse',
            icon: IconHome,
            privilege: [PRIVILEGES.PROPERTY_TYPE_MANAGEMENT],
            children: [
                {
                    id: 'all-property-type',
                    title: 'All Property Types',
                    type: 'item',
                    url: '/PropertyType',
                    icon: IconHome,
                    privilege: [PRIVILEGES.PROPERTY_TYPE_MANAGEMENT],
                    breadcrumbs: false
                },
                {
                    id: 'add-property-type',
                    title: 'Add Property Types',
                    type: 'item',
                    url: '/PropertyType/add',
                    icon: IconHome,
                    privilege: [PRIVILEGES.PROPERTY_TYPE_MANAGEMENT],
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'property-status',
            title: 'Property Status',
            type: 'collapse',
            privilege: [PRIVILEGES.PROPERTY_STATUS_MANAGEMENT],
            icon: () => {
                return <i className="fa-solid fa-hourglass"></i>;
            },

            children: [
                {
                    id: 'all-property-status',
                    title: 'All Property Status',
                    type: 'item',
                    url: '/PropertyStatus',
                    privilege: [PRIVILEGES.PROPERTY_STATUS_MANAGEMENT],
                    icon: () => {
                        return <i className="fa-solid fa-hourglass"></i>;
                    },
                    breadcrumbs: false
                },
                {
                    id: 'add-property-status',
                    title: 'Add Property Status',
                    type: 'item',
                    privilege: [PRIVILEGES.PROPERTY_STATUS_MANAGEMENT],
                    url: '/PropertyStatus/add',
                    icon: () => {
                        return <i className="fa-solid fa-hourglass"></i>;
                    },
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'other-property-details',
            title: 'Other Property Details ',
            type: 'collapse',

            icon: IconHome,
            privilege: [PRIVILEGES.OTHER_PROPERTY_DETAILS_MANAGEMENT, PRIVILEGES.OTHER_DETAIL_MANAGEMENT],
            children: [
                {
                    id: 'all-other-property-details',
                    title: 'All Other Property Details',
                    type: 'item',
                    privilege: [PRIVILEGES.OTHER_PROPERTY_DETAILS_MANAGEMENT, PRIVILEGES.OTHER_DETAIL_MANAGEMENT],

                    url: '/OtherPropertyDetails',
                    icon: IconHome,
                    breadcrumbs: false
                },
                {
                    id: 'add-other-property-details',
                    title: 'Add Other Property Details',
                    type: 'item',
                    url: '/OtherPropertyDetails/add',
                    icon: IconHome,
                    privilege: [PRIVILEGES.OTHER_PROPERTY_DETAILS_MANAGEMENT, PRIVILEGES.OTHER_DETAIL_MANAGEMENT],

                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'property-features',
            title: 'property Features ',
            type: 'collapse',

            icon: () => {
                return <i className="fa-solid fa-star"></i>;
            },
            privilege: [PRIVILEGES.PROPERTY_FEATURES_MANAGEMENT],
            children: [
                {
                    id: 'all-property-features',
                    title: 'All property Features',
                    type: 'item',
                    url: '/PropertyFeatures',
                    icon: () => {
                        return <i className="fa-solid fa-star"></i>;
                    },
                    privilege: [PRIVILEGES.PROPERTY_FEATURES_MANAGEMENT],

                    breadcrumbs: false
                },
                {
                    id: 'add-property-features',
                    title: 'Add property Features',
                    type: 'item',
                    url: '/PropertyFeatures/add',
                    icon: () => {
                        return <i className="fa-solid fa-star"></i>;
                    },
                    privilege: [PRIVILEGES.PROPERTY_FEATURES_MANAGEMENT],

                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'property-tags',
            title: 'property Tags ',
            type: 'collapse',

            icon: () => {
                return <i className="fa-solid fa-tags"></i>;
            },
            privilege: [PRIVILEGES.PROPERTY_TAGS_MANAGEMENT],
            children: [
                {
                    id: 'all-property-tags',
                    title: 'All property Tags',
                    type: 'item',
                    url: '/PropertyTags',
                    icon: () => {
                        return <i className="fa-solid fa-tags"></i>;
                    },
                    privilege: [PRIVILEGES.PROPERTY_TAGS_MANAGEMENT],

                    breadcrumbs: false
                },
                {
                    id: 'add-property-tags',
                    title: 'Add property Tags',
                    type: 'item',
                    url: '/PropertyTags/add',
                    icon: () => {
                        return <i className="fa-solid fa-tags"></i>;
                    },
                    privilege: [PRIVILEGES.PROPERTY_TAGS_MANAGEMENT],

                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default property;
