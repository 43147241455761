// assets
import { IconLanguage } from '@tabler/icons';
import PRIVILEGES from 'env/privileges';

// constant
const icons = {
    IconLanguage
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const Languages = {
    id: 'Languages',
    title: 'Languages',
    caption: 'Languages Management',
    type: 'group',
    privilege: [PRIVILEGES.LANGUAGE_MANAGEMENT],
    children: [
        {
            id: 'AllLanguages',
            title: 'All Languages',
            type: 'item',
            icon: icons.IconLanguage,
            url: '/Languages',
            privilege: [PRIVILEGES.LANGUAGE_MANAGEMENT]
        },
        {
            id: 'AddLanguage',
            title: 'Add New Languages',
            type: 'item',
            icon: icons.IconLanguage,
            url: '/Languages/add',
            privilege: [PRIVILEGES.LANGUAGE_MANAGEMENT]
        }
    ]
};

export default Languages;
