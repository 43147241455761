import { authAxios } from 'config/axios';
import {
    POST_ADD_PROPERTY,
    PUT_FEATURE_PROPERTY_BY_ID,
    PUT_CHECK_PROPERTY,
    PUT_COPY_WRITE_PROPERTY,
    GET_ALL_CHECKER_PROPERTIES,
    GET_ALL_PROPERTIES,
    GET_ALL_NEED_REMAKE_PROPERTIES,
    PUT_HOT_OFFER_PROPERTY_BY_ID,
    PUT_TOGGLE_ACTIVE_BY_PROPERTY_ID,
    GET_ALL_COPY_WRITER_PROPERTIES,
    GET_ALL_PUBLISHED_PROPERTIES,
    PUT_UPDATE_PROPERTY,
    PUT_REMAKE_PROPERTY
} from 'env/server';
import getErrorMassage from 'helpers/Errors/getErrorMassage';
import * as actionTypes from './types';
export const successProperty = (res) => {
    return {
        type: actionTypes.SUCCESS_PROPERTY,
        payload: res
        
    };
};

export const requestProperty = () => {
    return {
        type: actionTypes.REQUST_PROPERTY
    };
};
export const errorProperty = (error) => {
    console.log(error?.response);

    const message = getErrorMassage(error);
    return {
        type: actionTypes.FAILED_PROPERTY,
        payload: message
    };
};
export const getAllProperties = () => {
    return (dispatch) => {
        dispatch(requestProperty());
        authAxios
            .get(GET_ALL_PROPERTIES)
            .then((res) => {
                
                const AllProperties = res.data;
                dispatch(successProperty({ AllProperties }));
            })
            .catch((error) => {
                dispatch(errorProperty(error));
            });
    };
};
export const getAllNeedCopyWriteProperties = () => {
    return (dispatch) => {
        dispatch(requestProperty());
        authAxios
            .get(GET_ALL_COPY_WRITER_PROPERTIES)
            .then((res) => {
                
                const needCopyWriteProperties = res.data;
                dispatch(successProperty({ needCopyWriteProperties }));
            })
            .catch((error) => {
                dispatch(errorProperty(error));
            });
    };
};
export const getAllCheckProperties = () => {
    return (dispatch) => {
        dispatch(requestProperty());
        authAxios
            .get(GET_ALL_CHECKER_PROPERTIES)
            .then((res) => {
                
                const needCheckeProperties = res.data;
                dispatch(successProperty({ needCheckeProperties }));
            })
            .catch((error) => {
                dispatch(errorProperty(error));
            });
    };
};
export const getAllNeedRemakeProperties = () => {
    return (dispatch) => {
        dispatch(requestProperty());
        authAxios
            .get(GET_ALL_NEED_REMAKE_PROPERTIES)
            .then((res) => {
                
                const needRemakeProperties = res.data;
                dispatch(successProperty({ needRemakeProperties }));
            })
            .catch((error) => {
                dispatch(errorProperty(error));
            });
    };
};
export const getAllPublishedProperties = () => {
    return (dispatch) => {
        dispatch(requestProperty());
        authAxios
            .get(GET_ALL_PUBLISHED_PROPERTIES)
            .then((res) => {
                
                const publishedProperties = res.data;
                dispatch(successProperty({ publishedProperties }));
            })
            .catch((error) => {
                dispatch(errorProperty(error));
            });
    };
};
export const reMakeProperty = (values,id, feadBacke) => {
    return (dispatch) => {
        dispatch(requestProperty());
        authAxios
            .put(`${PUT_REMAKE_PROPERTY}/${id}`, values)
            .then((res) => {
                const needRemakeProperties = res.data;

                dispatch(successProperty({ needRemakeProperties }));
                feadBacke();
            })
            .catch((error) => {
                dispatch(errorProperty(error));
            });
    };
};
export const addNewProperty = (values, feadBacke) => {
    return (dispatch) => {
        dispatch(requestProperty());
        authAxios
            .post(POST_ADD_PROPERTY, values)
            .then((res) => {
                const AllProperties = res.data;

                dispatch(successProperty({ AllProperties }));
                feadBacke();
            })
            .catch((error) => {
                dispatch(errorProperty(error));
            });
    };
};
export const copyWriteProperty = (values,id, feadBacke) => {
    
    return (dispatch) => {
        dispatch(requestProperty());
        authAxios
            .put(`${PUT_COPY_WRITE_PROPERTY}/${id}`, values)
            .then((res) => {
                feadBacke();
                const needCopyWriteProperties = res.data;

                dispatch(successProperty({ needCopyWriteProperties }));
              
            })
            .catch((error) => {
                dispatch(errorProperty(error));
            });
    };
};
export const checkProperty = (values,id, feadBacke) => {
    return (dispatch) => {
        dispatch(requestProperty());
        authAxios
            .put(`${PUT_CHECK_PROPERTY}/${id}`, values)
            .then((res) => {
                const needCheckeProperties = res.data;

                dispatch(successProperty({ needCheckeProperties }));
                feadBacke();
            })
            .catch((error) => {
                dispatch(errorProperty(error));
            });
    };
};

export const toggleFeatureProperty = (id,callBake) => {
    return (dispatch) => {
        dispatch(requestProperty());
        authAxios
            .put(`${PUT_FEATURE_PROPERTY_BY_ID}/${id}`)
            .then((res) => {
                const AllProperties = res.data;

                dispatch(successProperty({ AllProperties }));
                if(callBake){
                    callBake()
                }
            })
            .catch((error) => {
                
                dispatch(errorProperty(error));
            });
    };
};
export const toggleActiveProperty = (id,callBake) => {
    return (dispatch) => {
        dispatch(requestProperty());
        authAxios
            .put(`${PUT_TOGGLE_ACTIVE_BY_PROPERTY_ID}/${id}`)
            .then((res) => {
                const AllProperties = res.data;

                dispatch(successProperty({ AllProperties }));
                if(callBake){
                    callBake()
                }
            })
            .catch((error) => {
                
                dispatch(errorProperty(error));
            });
    };
};
export const toggleHotOfferProperty = (id,callBake) => {
    return (dispatch) => {
        dispatch(requestProperty());
        authAxios
            .put(`${PUT_HOT_OFFER_PROPERTY_BY_ID}/${id}`)
            .then((res) => {
                const AllProperties = res.data;
                if(callBake){
                    callBake()
                }
                dispatch(successProperty({ AllProperties }));
            })
            .catch((error) => {
                
                dispatch(errorProperty(error));
            });
    };
};
export const goRemake = (id,callBacke) => {
    return (dispatch) => {
        dispatch(requestProperty());
        authAxios
            .put(`${PUT_UPDATE_PROPERTY}/${id}`)
            .then((res) => {
                const AllProperties = res.data;
                callBacke()
                dispatch(successProperty({ AllProperties }));
            })
            .catch((error) => {
                
                dispatch(errorProperty(error));
            });
    };
};