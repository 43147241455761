// assets
import { IconMessage } from '@tabler/icons';
import PRIVILEGES from 'env/privileges';

// constant
const icons = {
    IconMessage
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const Messages = {
    id: 'Messages',
    title: 'CRM',
    caption: 'Messages',
    type: 'group',
    privilege: [PRIVILEGES.SALES_MANAGEMENT],
    children: [
        {
            id: 'My Messages',
            title: 'My Messages',
            type: 'item',
            icon: icons.IconMessage,
            privilege: [PRIVILEGES.SALES_MANAGEMENT],
            url: '/messages'
        }
    ]
};

export default Messages;
