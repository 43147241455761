import { authAxios } from 'config/axios';
import { ADD_PROPERTY_FEATURE, DELETE_PROPERTY_FEATURE, GET_ALL_PROPERTY_FEATURE, UPDATE_PROPERTY_FEATURE } from 'env/server';
import * as actionTypes from './types';
export const successPropertyFeatures = (res) => {
    return {
        type: actionTypes.SUCCESS_PROPERTY_FEATURE,
        payload: res
    };
};

export const requestPropertyFeatures = () => {
    return {
        type: actionTypes.REQUST_PROPERTY_FEATURE
    };
};
export const errorPropertyFeatures = (error) => {
    

    // const message = getErrorMessage(error);
    const message = 'There is error';
    return {
        type: actionTypes.FAILED_PROPERTY_FEATURE,
        payload: message
    };
};
export const getPropertyFeatures = () => {
    return (dispatch) => {
        dispatch(requestPropertyFeatures());
        authAxios
            .get(GET_ALL_PROPERTY_FEATURE)
            .then((res) => {
                
                const propertyFeatures=res.data
                dispatch(successPropertyFeatures({propertyFeatures}))
            })
            .catch((error) => {
                dispatch(errorPropertyFeatures(error));

            });
    };
};
export const addNewPropertyFeatures = (values,feadBacke) => {
    return (dispatch) => {
        dispatch(requestPropertyFeatures());
        authAxios
            .post(ADD_PROPERTY_FEATURE,values)
            .then((res) => {
                const propertyFeatures=res.data
                dispatch(successPropertyFeatures({propertyFeatures}))
                feadBacke()
            })
            .catch((error) => {
                dispatch(errorPropertyFeatures(error));

            });
    };
};

export const deletePropertyFeatures = (id) => {
    return (dispatch) => {
        dispatch(requestPropertyFeatures());
        authAxios
            .delete(`${DELETE_PROPERTY_FEATURE}/${id}`)
            .then((res) => {
                const propertyFeatures=res.data
                dispatch(successPropertyFeatures({ propertyFeatures }));
            })
            .catch((error) => {
                
                dispatch(errorPropertyFeatures(error));
            });
    };
};
export const editPropertyFeatures= (id,values,sucsessFeedback) => {
    return (dispatch) => {
        dispatch(requestPropertyFeatures());
        authAxios
            .put(`${UPDATE_PROPERTY_FEATURE}/${id}`,values)
            .then((res) => {
                const propertyFeatures=res.data
                dispatch(successPropertyFeatures({ propertyFeatures }));
                sucsessFeedback()
            })
            .catch((error) => {

                
                dispatch(errorPropertyFeatures(error));
            });
    };
};
