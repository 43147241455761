import { authAxios } from 'config/axios';
import { ADD_CITY, DELETE_CITY, GET_ALL_CITY, GET_CITES_IN_COUNTRY, UPDATE_CITY } from 'env/server';
import * as actionTypes from './types';
export const successCity = (res) => {
    return {
        type: actionTypes.SUCCESS_CITY,
        payload: res
    };
};

export const requestCity = () => {
    return {
        type: actionTypes.REQUST_CITY
    };
};

export const errorCity = (error) => {
    

    // const message = getErrorMessage(error);
    const message = 'There is error';
    return {
        type: actionTypes.FAILED_CITY,
        payload: message
    };
};


export const getCityInCountry = (id) => {
    return (dispatch) => {
        dispatch(requestCity());
        authAxios
            .get(`${GET_CITES_IN_COUNTRY}/${id}`)
            .then((res) => {
                
                const cities=res.data
                dispatch(successCity({cities}))
            })
            .catch((error) => {
                dispatch(errorCity(error));

            });
    };
};
export const addNewCity = (values,feadBacke) => {
    return (dispatch) => {
        dispatch(requestCity());
        authAxios
            .post(ADD_CITY,values)
            .then((res) => {
                const cities=res.data
                dispatch(successCity({cities}))
                feadBacke()
            })
            .catch((error) => {
                dispatch(errorCity(error));

            });
    };
};

export const deleteCity = (id,feadBacke) => {
    return (dispatch) => {
        dispatch(requestCity());
        authAxios
            .delete(`${DELETE_CITY}/${id}`)
            .then((res) => {
                const City=res.data
                feadBacke()
                dispatch(successCity({ City }));
            })
            .catch((error) => {
                
                dispatch(errorCity(error));
            });
    };
};
export const editCity= (id,values,sucsessFeedback) => {
    return (dispatch) => {
        dispatch(requestCity());
        authAxios
            .put(`${UPDATE_CITY}/${id}`,values)
            .then((res) => {
                const City=res.data
                dispatch(successCity({ City }));
                sucsessFeedback()
            })
            .catch((error) => {

                
                dispatch(errorCity(error));
            });
    };
};
