import * as actionTypes from './types';

const initialState = {
    loading: false,
    error: false,
    settings: {}
};
const settingReducer = (state = { ...initialState }, action) => {
    const payload = action.payload;
    switch (action.type) {
        case actionTypes.GET_SETTINGS_REQUEST:
        case actionTypes.UPDATE_SETTINGS_REQUEST: {
            return {
                ...state,
                error: null,
                loading: true
            };
        }
        case actionTypes.GET_SETTINGS_SUCCESS:
        case actionTypes.UPDATE_SETTINGS_SUCCESS: {
            return {
                ...state,
                settings: payload,
                loading: false,
                error: false
            };
        }
        case actionTypes.GET_SETTINGS_FAILED:
        case actionTypes.UPDATE_SETTINGS_FAILED: {
            return {
                ...state,
                loading: false,
                error: payload
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
};

export default settingReducer;
