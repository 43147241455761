import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const NotAllowed = Loadable(lazy(() => import('views/NotAllowed')));
const RoleDetales = Loadable(lazy(() => import('views/roles/RoleDetales')));
const AllRoles = Loadable(lazy(() => import('views/roles/AllRoles')));
const AddNewRole = Loadable(lazy(() => import('views/roles/AddNewRole')));
const AllUsers = Loadable(lazy(() => import('views/Users/AllUsers')));
const EditUser = Loadable(lazy(() => import('views/Users/EditUser')));
const AddNewUser = Loadable(lazy(() => import('views/Users/AddNewUser')));
const AllLanguages = Loadable(lazy(() => import('views/language/AllLanguages')));
const EditLanguage = Loadable(lazy(() => import('views/language/EditLanguage')));
const AddNewLanguage = Loadable(lazy(() => import('views/language/AddNewLanguage')));
const EditMyAccount = Loadable(lazy(() => import('views/Users/EditMyAccount')));
const ResetPassword = Loadable(lazy(() => import('views/Users/ResetPassword')));
const ResetMyPassword = Loadable(lazy(() => import('views/Users/ResetMyPassword')));
const AllPropertyTypes = Loadable(lazy(() => import('views/Property/PropertyType/AllPropertyTypes')));
const AddPropertyType = Loadable(lazy(() => import('views/Property/PropertyType/AddPropertyType')));
const EditPropertyType = Loadable(lazy(() => import('views/Property/PropertyType/EditPropertyType')));
const EditPropertyStatus = Loadable(lazy(() => import('views/Property/PropertyStatus/EditPropertyStatus')));
const AllPropertyStatus = Loadable(lazy(() => import('views/Property/PropertyStatus/AllPropertyStatus')));
const AddPropertyStatus = Loadable(lazy(() => import('views/Property/PropertyStatus/AddPropertyStatus')));
const AddOtherPropertyDetails = Loadable(lazy(() => import('views/Property/OtherPropertyDetails/AddOtherPropertyDetails')));
const AlltherPropertyDetails = Loadable(lazy(() => import('views/Property/OtherPropertyDetails/AllOtherPropertyDetails')));
const EditOtherPropertyDetails = Loadable(lazy(() => import('views/Property/OtherPropertyDetails/EditOtherPropertyDetails')));
const AddPropertyFeatures = Loadable(lazy(() => import('views/Property/PropertyFeatures/AddPropertyFeatures')));
const AllPropertyFeatures = Loadable(lazy(() => import('views/Property/PropertyFeatures/AllPropertyFeatures')));
const EditPropertyFeatures = Loadable(lazy(() => import('views/Property/PropertyFeatures/EditPropertyFeatures')));
const AddPropertyTags = Loadable(lazy(() => import('views/Property/PropertyTags/AddPropertyTags')));
const AllPropertyTags = Loadable(lazy(() => import('views/Property/PropertyTags/AllPropertyTags')));
const EditPropertyTags = Loadable(lazy(() => import('views/Property/PropertyTags/EditPropertyTags')));
const AllDistricts = Loadable(lazy(() => import('views/Districts/AllDistricts')));
const AddNewDistricts = Loadable(lazy(() => import('views/Districts/AddNewDistricts')));
const EditDistrict = Loadable(lazy(() => import('views/Districts/EditDistrict')));
const AddNewCity = Loadable(lazy(() => import('views/Cities/AddNewCity')));
const AllCities = Loadable(lazy(() => import('views/Cities/AllCities')));
const EditCity = Loadable(lazy(() => import('views/Cities/EditCity')));
const AllCountries = Loadable(lazy(() => import('views/Country/AllCountries')));
const AddNewCountry = Loadable(lazy(() => import('views/Country/AddNewCountry')));
const EditCountry = Loadable(lazy(() => import('views/Country/EditCountry')));
const AllCurrencies = Loadable(lazy(() => import('views/Currency/AllCurrencies')));
const AddNewCurrency = Loadable(lazy(() => import('views/Currency/AddNewCurrency')));
const EditCurrency = Loadable(lazy(() => import('views/Currency/EditCurrency')));
//AddNewProperty
const AddNewProperty = Loadable(lazy(() => import('views/Property/AddNewProperty/Maker/AddNewProperty')));
const AllProperties = Loadable(lazy(() => import('views/Property/AddNewProperty/Admin/AllProperties')));
const PublishedProperties = Loadable(lazy(() => import('views/Property/AddNewProperty/Admin/PublishedProperties')));
const MackerRejectedPropereties = Loadable(lazy(() => import('views/Property/AddNewProperty/Maker/MackerRejectedPropereties')));
const RemakeProperety = Loadable(lazy(() => import('views/Property/AddNewProperty/Maker/RemakeProperety')));
const ProperetyDeteles = Loadable(lazy(() => import('views/Property/AddNewProperty/Admin/ProperetyDeteles')));

const CopyWriterProperyDeteles = Loadable(lazy(() => import('views/Property/AddNewProperty/CopyWriter/CopyWriterProperyDeteles')));
const CopyWriteProperety = Loadable(lazy(() => import('views/Property/AddNewProperty/CopyWriter/CopyWriteProperety')));
const CopyWriterRejectedPropereties = Loadable(
    lazy(() => import('views/Property/AddNewProperty/CopyWriter/CopyWriterRejectedPropereties'))
);
const ProperetiesNeedToCopyWrite = Loadable(lazy(() => import('views/Property/AddNewProperty/CopyWriter/ProperetiesNeedToCopyWrite')));
const CheckerProperetyDetels = Loadable(lazy(() => import('views/Property/AddNewProperty/Checker/CheckerProperetyDetels')));
const ProperetiesNeedToCheck = Loadable(lazy(() => import('views/Property/AddNewProperty/Checker/ProperetiesNeedToCheck')));

// content
const EditAboutUs = Loadable(lazy(() => import('views/Content/EditAboutUs')));
const EditContactUs = Loadable(lazy(() => import('views/Content/EditContactUs')));
const EditPrivacyPolicy = Loadable(lazy(() => import('views/Content/EditPrivacyPolicy')));
const EditCookiePolicy = Loadable(lazy(() => import('views/Content/EditCookiePolicy')));
const EditLegalNoticesDisclaimer = Loadable(lazy(() => import('views/Content/EditLegalNoticesDisclaimer')));
const EditTermsUse = Loadable(lazy(() => import('views/Content/EditTermsUse')));

// service
const AllServices = Loadable(lazy(() => import('views/Service/AllServices')));
const AddNewService = Loadable(lazy(() => import('views/Service/AddNewService')));
const EditService = Loadable(lazy(() => import('views/Service/EditService')));

// image slider
const AllImageSliders = Loadable(lazy(() => import('views/ImageSlider/AllImageSliders')));
const AddNewImageSlider = Loadable(lazy(() => import('views/ImageSlider/AddNewImageSlider')));
const EditImageSlider = Loadable(lazy(() => import('views/ImageSlider/EditImageSlider')));
// setting
const EditSettings = Loadable(lazy(() => import('views/Setting/EditSettings')));

// contact us messages (sales manager)
const MyAllContactUsMessages = Loadable(lazy(() => import('views/ContactUsMessages/MyAllContactUsMessages')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <SamplePage />
        },
        {
            path: '/not-allowed',
            element: <NotAllowed />
        },
        {
            path: '/Roles',
            element: <AllRoles />
        },
        {
            path: '/Roles/add',
            element: <AddNewRole />
        },
        {
            path: '/Roles/:role_id',
            element: <RoleDetales />
        },
        {
            path: '/Languages',
            element: <AllLanguages />
        },
        {
            path: '/Languages/:Language_id',
            element: <EditLanguage />
        },
        {
            path: '/Languages/add',
            element: <AddNewLanguage />
        },

        {
            path: '/Users',
            element: <AllUsers />
        },
        {
            path: '/Users/:user_id',
            element: <EditUser />
        },
        {
            path: '/Users/:user_id/ResetPassword',
            element: <ResetPassword />
        },
        {
            path: '/EditMyAccount',
            element: <EditMyAccount />
        },
        {
            path: '/EditMyAccount/ResetPassword',
            element: <ResetMyPassword />
        },
        {
            path: '/Users/Add',
            element: <AddNewUser />
        },

        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/PropertyType',
            element: <AllPropertyTypes />
        },
        {
            path: '/PropertyType/add',
            element: <AddPropertyType />
        },

        {
            path: '/PropertyType/:property_id',
            element: <EditPropertyType />
        },
        {
            path: '/PropertyStatus',
            element: <AllPropertyStatus />
        },
        {
            path: '/PropertyStatus/add',
            element: <AddPropertyStatus />
        },

        {
            path: '/PropertyStatus/:property_id',
            element: <EditPropertyStatus />
        },
        {
            path: '/OtherPropertyDetails',
            element: <AlltherPropertyDetails />
        },
        {
            path: '/OtherPropertyDetails/add',
            element: <AddOtherPropertyDetails />
        },

        {
            path: '/OtherPropertyDetails/:property_id',
            element: <EditOtherPropertyDetails />
        },
        {
            path: '/PropertyFeatures',
            element: <AllPropertyFeatures />
        },
        {
            path: '/PropertyFeatures/add',
            element: <AddPropertyFeatures />
        },

        {
            path: '/PropertyFeatures/:property_id',
            element: <EditPropertyFeatures />
        },
        {
            path: '/PropertyTags',
            element: <AllPropertyTags />
        },
        {
            path: '/PropertyTags/add',
            element: <AddPropertyTags />
        },

        {
            path: '/PropertyTags/:property_id',
            element: <EditPropertyTags />
        },

        {
            path: '/AddNewProperty',
            element: <AddNewProperty />
        },
        {
            path: '/all-properties',
            element: <AllProperties />
        },
        {
            path: '/published-properties',
            element: <PublishedProperties />
        },
        {
            path: '/all-properties/:id',
            element: <ProperetyDeteles />
        },
        {
            path: '/maker-rejected-properties',
            element: <MackerRejectedPropereties />
        },
        {
            path: '/maker-rejected-properties/:id',
            element: <RemakeProperety />
        },

        {
            path: '/properties-need-to-check',
            element: <ProperetiesNeedToCheck />
        },
        {
            path: '/properties-need-to-check/:id',
            element: <CheckerProperetyDetels />
        },
        {
            path: '/properties-need-to-copy-write',
            element: <ProperetiesNeedToCopyWrite />
        },
        {
            path: '/copy-writer-rejected-properties',
            element: <CopyWriterRejectedPropereties />
        },
        {
            path: '/properties-need-to-copy-write/:id/deteles',
            element: <CopyWriterProperyDeteles />
        },
        {
            path: '/properties-need-to-copy-write/:id',
            element: <CopyWriteProperety />
        },

        {
            path: '/Districts',
            element: <AllDistricts />
        },
        {
            path: '/Districts/add',
            element: <AddNewDistricts />
        },
        {
            path: '/Districts/:city_id/:district_id',
            element: <EditDistrict />
        },
        {
            path: '/Cities',
            element: <AllCities />
        },
        {
            path: '/Cities/:country_id/:city_id',
            element: <EditCity />
        },
        {
            path: '/Cities/add',
            element: <AddNewCity />
        },
        {
            path: '/Countres',
            element: <AllCountries />
        },
        {
            path: '/Countres/:country_id',
            element: <EditCountry />
        },
        {
            path: '/Countres/add',
            element: <AddNewCountry />
        },
        {
            path: '/currencies',
            element: <AllCurrencies />
        },
        {
            path: '/currencies/add',
            element: <AddNewCurrency />
        },
        {
            path: '/currencies/:currency_id',
            element: <EditCurrency />
        },
        {
            path: '/about-us/edit',
            element: <EditAboutUs />
        },
        {
            path: '/contact-us/edit',
            element: <EditContactUs />
        },
        {
            path: '/privacy-policy/edit',
            element: <EditPrivacyPolicy />
        },
        {
            path: '/cookie-policy/edit',
            element: <EditCookiePolicy />
        },
        {
            path: '/legal-notices-disclaimer/edit',
            element: <EditLegalNoticesDisclaimer />
        },
        {
            path: '/terms-use/edit',
            element: <EditTermsUse />
        },
        {
            path: '/services',
            element: <AllServices />
        },
        {
            path: '/services/add',
            element: <AddNewService />
        },
        {
            path: '/services/:service_id',
            element: <EditService />
        },
        {
            path: '/image-sliders',
            element: <AllImageSliders />
        },
        {
            path: '/image-sliders/add',
            element: <AddNewImageSlider />
        },
        {
            path: '/image-sliders/:image_slider_id',
            element: <EditImageSlider />
        },
        {
            path: '/settings',
            element: <EditSettings />
        },
        {
            path: '/messages',
            element: <MyAllContactUsMessages />
        },
        {
            path: '*',
            element: <h1>This page not found</h1>
        }
    ]
};

export default MainRoutes;
