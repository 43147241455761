export const GET_ALL_SERVICES_REQUEST = 'GET_ALL_SERVICES_REQUEST';
export const GET_ALL_SERVICES_SUCCESS = 'GET_ALL_SERVICES_SUCCESS';
export const GET_ALL_SERVICES_FAILED = 'GET_ALL_SERVICES_FAILED';

export const CREATE_NEW_SERVICE_REQUEST = 'CREATE_NEW_SERVICE_REQUEST';
export const CREATE_NEW_SERVICE_SUCCESS = 'CREATE_NEW_SERVICE_SUCCESS';
export const CREATE_NEW_SERVICE_FAILED = 'CREATE_NEW_SERVICE_FAILED';

export const UPDATE_SERVICE_REQUEST = 'UPDATE_SERVICE_REQUEST';
export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS';
export const UPDATE_SERVICE_FAILED = 'UPDATE_SERVICE_FAILED';

export const DELETE_SERVICE_REQUEST = 'DELETE_SERVICE_REQUEST';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_FAILED = 'DELETE_SERVICE_FAILED';
