export const GET_ALL_IMAGE_SLIDERS_REQUEST = 'GET_ALL_IMAGE_SLIDERS_REQUEST';
export const GET_ALL_IMAGE_SLIDERS_SUCCESS = 'GET_ALL_IMAGE_SLIDERS_SUCCESS';
export const GET_ALL_IMAGE_SLIDERS_FAILED = 'GET_ALL_IMAGE_SLIDERS_FAILED';

export const CREATE_NEW_IMAGE_SLIDER_REQUEST = 'CREATE_NEW_IMAGE_SLIDER_REQUEST';
export const CREATE_NEW_IMAGE_SLIDER_SUCCESS = 'CREATE_NEW_IMAGE_SLIDER_SUCCESS';
export const CREATE_NEW_IMAGE_SLIDER_FAILED = 'CREATE_NEW_IMAGE_SLIDER_FAILED';

export const UPDATE_IMAGE_SLIDER_REQUEST = 'UPDATE_IMAGE_SLIDER_REQUEST';
export const UPDATE_IMAGE_SLIDER_SUCCESS = 'UPDATE_IMAGE_SLIDER_SUCCESS';
export const UPDATE_IMAGE_SLIDER_FAILED = 'UPDATE_IMAGE_SLIDER_FAILED';

export const TOGGLE_IS_FEATURE_IMAGE_SLIDER_REQUEST = 'TOGGLE_IS_FEATURE_IMAGE_SLIDER_REQUEST';
export const TOGGLE_IS_FEATURE_IMAGE_SLIDER_SUCCESS = 'TOGGLE_IS_FEATURE_IMAGE_SLIDER_SUCCESS';
export const TOGGLE_IS_FEATURE_IMAGE_SLIDER_FAILED = 'TOGGLE_IS_FEATURE_IMAGE_SLIDER_FAILED';

export const DELETE_IMAGE_SLIDER_REQUEST = 'DELETE_IMAGE_SLIDER_REQUEST';
export const DELETE_IMAGE_SLIDER_SUCCESS = 'DELETE_IMAGE_SLIDER_SUCCESS';
export const DELETE_IMAGE_SLIDER_FAILED = 'DELETE_IMAGE_SLIDER_FAILED';
