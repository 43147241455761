// assets
import { IconTypography, IconPalette, IconHome, IconShadow, IconWindmill, IconUsers, IconUserPlus } from '@tabler/icons';
import PRIVILEGES from 'env/privileges';
// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill
};

// ==============================|| property MENU ITEMS ||============================== //

const Country = {
    id: 'Country',
    title: 'Country & City & Districts',
    caption: 'Location Management',
    type: 'group',
    privilege: [PRIVILEGES.COUNTRY_STATES_DISTRICT_MANAGEMENT],
    children: [
        {
            id: 'country-type',
            title: 'Countres',
            type: 'collapse',
            icon: () => {
                return <i className="fa-solid fa-earth-asia"></i>;
            },
            privilege: [PRIVILEGES.COUNTRY_STATES_DISTRICT_MANAGEMENT],
            children: [
                {
                    id: 'all-country',
                    title: 'All Countries',
                    type: 'item',
                    url: '/Countres',
                    privilege: [PRIVILEGES.COUNTRY_STATES_DISTRICT_MANAGEMENT],
                    icon: () => {
                        return <i className="fa-solid fa-earth-asia"></i>;
                    },
                    breadcrumbs: false
                },
                {
                    id: 'add-country',
                    title: 'Add Country',
                    type: 'item',
                    url: '/Countres/add',
                    icon: () => {
                        return <i className="fa-solid fa-earth-asia"></i>;
                    },
                    privilege: [PRIVILEGES.COUNTRY_STATES_DISTRICT_MANAGEMENT],
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'property-City',
            title: 'City',
            type: 'collapse',

            icon: () => {
                return <i className="fa-solid fa-mountain-city"></i>;
            },
            privilege: [PRIVILEGES.COUNTRY_STATES_DISTRICT_MANAGEMENT],
            children: [
                {
                    id: 'all-City',
                    title: 'All Cities',
                    type: 'item',
                    url: '/Cities',
                    icon: () => {
                        return <i className="fa-solid fa-mountain-city"></i>;
                    },
                    privilege: [PRIVILEGES.COUNTRY_STATES_DISTRICT_MANAGEMENT],
                    breadcrumbs: false
                },
                {
                    id: 'add-Cities',
                    title: 'Add City',
                    type: 'item',
                    url: '/Cities/add',
                    icon: () => {
                        return <i className="fa-solid fa-mountain-city"></i>;
                    },
                    privilege: [PRIVILEGES.COUNTRY_STATES_DISTRICT_MANAGEMENT],
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'districts',
            title: 'Districts ',
            type: 'collapse',
            privilege: [PRIVILEGES.COUNTRY_STATES_DISTRICT_MANAGEMENT],
            icon: () => {
                return <i className="fa-solid fa-map-location-dot"></i>;
            },
            children: [
                {
                    id: 'all-districts',
                    title: 'All Districts',
                    type: 'item',
                    url: '/Districts',
                    icon: () => {
                        return <i className="fa-solid fa-map-location-dot"></i>;
                    },
                    privilege: [PRIVILEGES.COUNTRY_STATES_DISTRICT_MANAGEMENT],
                    breadcrumbs: false
                },
                {
                    id: 'add-districts',
                    title: 'Add District',
                    type: 'item',
                    url: '/Districts/add',
                    icon: () => {
                        return <i className="fa-solid fa-map-location-dot"></i>;
                    },
                    privilege: [PRIVILEGES.COUNTRY_STATES_DISTRICT_MANAGEMENT],
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default Country;
