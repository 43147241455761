export const GET_ALL_DISTRICTS_IN_CITY_REQUEST = 'GET_ALL_DISTRICTS_IN_CITY_REQUEST';
export const GET_ALL_DISTRICTS_IN_CITY_SUCCESS = 'GET_ALL_DISTRICTS_IN_CITY_SUCCESS';
export const GET_ALL_DISTRICTS_IN_CITY_FAILED = 'GET_ALL_DISTRICTS_IN_CITY_FAILED';

export const CREATE_NEW_DISTRICT_REQUEST = 'CREATE_NEW_DISTRICT_REQUEST';
export const CREATE_NEW_DISTRICT_SUCCESS = 'CREATE_NEW_DISTRICT_SUCCESS';
export const CREATE_NEW_DISTRICT_FAILED = 'CREATE_NEW_DISTRICT_FAILED';

export const UPDATE_DISTRICT_REQUEST = 'UPDATE_DISTRICT_REQUEST';
export const UPDATE_DISTRICT_SUCCESS = 'UPDATE_DISTRICT_SUCCESS';
export const UPDATE_DISTRICT_FAILED = 'UPDATE_DISTRICT_FAILED';

export const DELETE_DISTRICT_REQUEST = 'DELETE_DISTRICT_REQUEST';
export const DELETE_DISTRICT_SUCCESS = 'DELETE_DISTRICT_SUCCESS';
export const DELETE_DISTRICT_FAILED = 'DELETE_DISTRICT_FAILED';
