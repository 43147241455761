const PORT = process.env.PORT;
const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const REACT_APP_PROTOCOL = process.env.REACT_APP_PROTOCOL;
const REACT_APP_HOST = process.env.REACT_APP_HOST;
const REACT_APP_SERVER_PORT = process.env.REACT_APP_SERVER_PORT;

// console.log('PORT ', PORT);
// console.log('REACT_APP_ENVIRONMENT ', REACT_APP_ENVIRONMENT);
// console.log('REACT_APP_PROTOCOL ', REACT_APP_PROTOCOL);
// console.log('REACT_APP_HOST ', REACT_APP_HOST);
// console.log('REACT_APP_SERVER_PORT ', REACT_APP_SERVER_PORT);
// console.log('typeof ENVIRONMENT', typeof REACT_APP_ENVIRONMENT);
// console.log("REACT_APP_ENVIRONMENT == 'dev'", REACT_APP_ENVIRONMENT == 'dev');

const DEV_API_URL = `${REACT_APP_PROTOCOL}://${REACT_APP_HOST}:${REACT_APP_SERVER_PORT}`;
const NOT_DEV_API_URL = `${REACT_APP_PROTOCOL}://${REACT_APP_HOST}`;

// export const API_URL = `${REACT_APP_ENVIRONMENT == 'dev' ? DEV_API_URL : NOT_DEV_API_URL}/api/v1/dashboard`;
// export const STATIC_FILES_URL = `${REACT_APP_ENVIRONMENT == 'dev' ? DEV_API_URL : NOT_DEV_API_URL}/api/v1/`;

export const API_URL = `https://backend.tolerance-realestate.com/api/v1/dashboard`;
export const STATIC_FILES_URL = `https://backend.tolerance-realestate.com/api/v1/`;
// console.log('API_URL : ', API_URL);
// console.log('STATIC_FILES_URL : ', STATIC_FILES_URL);

export const LOG_IN_END_POINT = `${API_URL}/auth/login`;
export const LOGOUT_END_POINT = `${API_URL}/auth/logout`;
//static
export const GET_ALL_STATIC_DATA = `${API_URL}/static`;

//Account
export const ADD_NEW_ACCOUNT = `${API_URL}/account`;
export const GET_ALL_ACCOUNTS = `${API_URL}/account`;
export const GET_ACCOUNT_BY_ID = `${API_URL}/account`;
export const GET_ALL_ACCOUNTS_BY_ROLE = `${API_URL}/account/get-in-role`;
export const UPDATE_ACCOUNT = `${API_URL}/account`;
export const UPDATE_MY_ACCOUNT = `${API_URL}/account/profile/update`;
export const GET_MY_ACCOUNT = `${API_URL}/account/profile`;
export const DELETE_ACCOUNT = `${API_URL}/account`;
export const RESET_ACCOUNT_PASSWORD = `${API_URL}/account/reset_pwd`;
export const RESET_MY_PASSWORD = `${API_URL}/account/profile/reset_pwd`;
export const TOGGLE_IS_ACCTIVE_ACCOUNT = `${API_URL}/account/is_active`;
//Languages
export const GET_ALL_LANGUAGES = `${API_URL}/language`;
export const ADD_NEW_LANGUAGE = `${API_URL}/language`;
export const GET_IS_ACTIVE_LANGUGES = `${API_URL}/is_active`;
export const UPDATE_LANGUAGE = `${API_URL}/language`;
export const DELETE_LANGUAGE = `${API_URL}/language`;
export const TOGGLE_IS_ACCTIVE_LANGUAGE = `${API_URL}/language/is_active`;
//privileges

export const GET_ALL_PRIVILEGES = `${API_URL}/privilege`;

//roles
export const GET_ALL_ROLES = `${API_URL}/role`;
export const ADD_NEW_ROLE = `${API_URL}/role`;
export const UPDATE_ROLE = `${API_URL}/role`;
export const DELETE_ROLE = `${API_URL}/role`;

//property-type
export const GET_ALL_PROPERTY_TYPES = `${API_URL}/property-type`;
export const UPDATE_PROPERTY_TYPE = `${API_URL}/property-type`;
export const DELETE_PROPERTY_TYPE = `${API_URL}/property-type`;
export const ADD_NEW_PROPERTY_TYPE = `${API_URL}/property-type`;

//country
export const GET_ALL_COUNTRY = `${API_URL}/country`;
export const DELETE_COUNTRY = `${API_URL}/country`;
export const UPDATE_COUNTRY = `${API_URL}/country`;
export const ADD_COUNTRY = `${API_URL}/country`;
//city
export const GET_ALL_CITY = `${API_URL}/city`;
export const DELETE_CITY = `${API_URL}/city`;
export const UPDATE_CITY = `${API_URL}/city`;
export const ADD_CITY = `${API_URL}/city`;
export const GET_CITES_IN_COUNTRY = `${API_URL}/city/get-in-country`;
//district

export const GET_ALL_DISTRICTS_IN_CITY_END_POINT = `${API_URL}/district/get-in-city/`;
export const CREATE_NEW_DISTRICT_END_POINT = `${API_URL}/district`;
export const DELETE_DISTRICT_END_POINT = `${API_URL}/district/`;
export const UPDATE_DISTRICT_END_POINT = `${API_URL}/district/`;

//property-status
export const GET_ALL_PROPERTY_STATUS = `${API_URL}/property-status`;
export const UPDATE_PROPERTY_STATUS = `${API_URL}/property-status`;
export const DELETE_PROPERTY_STATUS = `${API_URL}/property-status`;
export const ADD_NEW_PROPERTY_STATUS = `${API_URL}/property-status`;

//other-property-details
export const GET_ALL_OTHER_PROPERTY_DETAILS = `${API_URL}/other-property-details`;
export const UPDATE_OTHER_PROPERTY_DETAILS = `${API_URL}/other-property-details`;
export const DELETE_OTHER_PROPERTY_DETAILS = `${API_URL}/other-property-details`;
export const ADD_OTHER_PROPERTY_DETAILS = `${API_URL}/other-property-details`;

//files
export const UPLOAD_FILE = `${STATIC_FILES_URL}file-manager`;

//property-feature
export const GET_ALL_PROPERTY_FEATURE = `${API_URL}/property-feature`;
export const UPDATE_PROPERTY_FEATURE = `${API_URL}/property-feature`;
export const DELETE_PROPERTY_FEATURE = `${API_URL}/property-feature`;
export const ADD_PROPERTY_FEATURE = `${API_URL}/property-feature`;

//property-tag
export const GET_ALL_PROPERTY_TAGS = `${API_URL}/property-tag`;
export const UPDATE_PROPERTY_TAGS = `${API_URL}/property-tag`;
export const DELETE_PROPERTY_TAGS = `${API_URL}/property-tag`;
export const ADD_PROPERTY_TAGS = `${API_URL}/property-tag`;

//property
export const GET_ALL_PROPERTIES = `${API_URL}/property`;
export const GET_PROPERTY_BY_ID = `${API_URL}/property`;
export const PUT_FEATURE_PROPERTY_BY_ID = `${API_URL}/property/featured`;
export const PUT_TOGGLE_ACTIVE_BY_PROPERTY_ID = `${API_URL}/property/active-inactive`;
export const PUT_HOT_OFFER_PROPERTY_BY_ID = `${API_URL}/property/hot-offer`;
export const PUT_UPDATE_PROPERTY = `${API_URL}/property/update-by-admin`;
export const GET_ALL_PUBLISHED_PROPERTIES = `${API_URL}/property/published`;

//property maker
export const POST_ADD_PROPERTY = `${API_URL}/property/make-property`;
export const PUT_REMAKE_PROPERTY = `${API_URL}/property/remake`;
export const GET_ALL_NEED_REMAKE_PROPERTIES = `${API_URL}/property/need_remake`;

//property copywriter

export const GET_ALL_COPY_WRITER_PROPERTIES = `${API_URL}/property/need_copywrite`;

export const PUT_COPY_WRITE_PROPERTY = `${API_URL}/property/copywrite`;

//property checker
export const GET_ALL_CHECKER_PROPERTIES = `${API_URL}/property/need_check`;
export const PUT_CHECK_PROPERTY = `${API_URL}/property/check`;

// content
export const GET_CONTENT_END_POINT = `${API_URL}/content/`;

// service
export const GET_ALL_SERVICES_END_POINT = `${API_URL}/service/`;
export const CREATE_NEW_SERVICE_END_POINT = `${API_URL}/service/`;
export const UPDATE_SERVICE_END_POINT = `${API_URL}/service/`;
export const DELETE_SERVICE_END_POINT = `${API_URL}/service/`;

// image slider
export const GET_ALL_IMAGE_SLIDERS_END_POINT = `${API_URL}/image-slider/`;
export const CREATE_NEW_IMAGE_SLIDER_END_POINT = `${API_URL}/image-slider/`;
export const UPDATE_IMAGE_SLIDER_END_POINT = `${API_URL}/image-slider/`;
export const TOGGLE_IS_FEATURE_IMAGE_SLIDER_END_POINT = `${API_URL}/image-slider/`;
export const DELETE_IMAGE_SLIDER_END_POINT = `${API_URL}/image-slider/`;

// setting
export const GET_SETTINGS_END_POINT = `${API_URL}/setting/`;
export const UPDATE_SETTINGS_END_POINT = `${API_URL}/setting/`;

// currency
export const CREATE_NEW_CURRENCY_END_POINT = `${API_URL}/currency/`;
export const GET_ALL_CURRENCIES_END_POINT = `${API_URL}/currency/`;
export const GET_ALL_SUPPORTED_CURRENCIES_END_POINT = `${API_URL}/currency/supported_file`;
export const DELETE_CURRENCY_END_POINT = `${API_URL}/currency/`;
export const UPDATE_CURRENCY_END_POINT = `${API_URL}/currency/`;
export const TOGGLE_IS_ACTIVE_CURRENCY_END_POINT = `${API_URL}/currency/toggle-is-active/`;

// messages
export const GET_MY_ALL_MESSAGES_END_POINT = `${API_URL}/sales-manager-message/get-my-messages`;
export const MARK_MESSAGE_AS_READ_END_POINT = `${API_URL}/sales-manager-message/mark-as-read/`;
export const MARK_MY_ALL_MESSAGES_AS_READ_END_POINT = `${API_URL}/sales-manager-message/mark-all-as-read`;
export const DELETE_MESSAGE_END_POINT = `${API_URL}/sales-manager-message/`;
