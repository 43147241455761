// assets
import { IconBrandChrome, IconHelp } from '@tabler/icons';
import favicon from 'assets/images/favicon.svg';
// constant
const icons = { IconBrandChrome, IconHelp };
// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    privilege: ['ANY'],
    children: [
        {
            id: 'datrsoft',

            title: `Copyright © 2022 devloped by Darsoft`,
            type: 'item',
            url: 'https://darsoft.net',
            privilege: ['ANY'],
            icon: '',
            external: true,
            target: true
        }
    ]
};

export default other;
