import { authAxios } from 'config/axios';
import { ADD_OTHER_PROPERTY_DETAILS, DELETE_OTHER_PROPERTY_DETAILS, GET_ALL_OTHER_PROPERTY_DETAILS, UPDATE_OTHER_PROPERTY_DETAILS } from 'env/server';
import * as actionTypes from './types';
export const successOtherPropertyDetails = (res) => {
    return {
        type: actionTypes.SUCCESS_OTHER_PROPERTY_DETAILS,
        payload: res
    };
};

export const requestOtherPropertyDetails = () => {
    return {
        type: actionTypes.REQUST_OTHER_PROPERTY_DETAILS
    };
};
export const errorOtherPropertyDetails = (error) => {
    

    // const message = getErrorMessage(error);
    const message = 'There is error';
    return {
        type: actionTypes.FAILED_OTHER_PROPERTY_DETAILS,
        payload: message
    };
};
export const getOtherPropertyDetails = () => {
    return (dispatch) => {
        dispatch(requestOtherPropertyDetails());
        authAxios
            .get(GET_ALL_OTHER_PROPERTY_DETAILS)
            .then((res) => {
                
                const otherPropertyDetails=res.data
                dispatch(successOtherPropertyDetails({otherPropertyDetails}))
            })
            .catch((error) => {
                dispatch(errorOtherPropertyDetails(error));

            });
    };
};
export const addNewOtherPropertyDetails = (values,feadBacke) => {
    return (dispatch) => {
        dispatch(requestOtherPropertyDetails());
        authAxios
            .post(ADD_OTHER_PROPERTY_DETAILS,values)
            .then((res) => {
                const otherPropertyDetails=res.data
                dispatch(successOtherPropertyDetails({otherPropertyDetails}))
                feadBacke()
            })
            .catch((error) => {
                dispatch(errorOtherPropertyDetails(error));

            });
    };
};

export const deleteOtherPropertyDetails = (id) => {
    return (dispatch) => {
        dispatch(requestOtherPropertyDetails());
        authAxios
            .delete(`${DELETE_OTHER_PROPERTY_DETAILS}/${id}`)
            .then((res) => {
                const otherPropertyDetails=res.data
                dispatch(successOtherPropertyDetails({ otherPropertyDetails }));
            })
            .catch((error) => {
                
                dispatch(errorOtherPropertyDetails(error));
            });
    };
};
export const editOtherPropertyDetails= (id,values,sucsessFeedback) => {
    return (dispatch) => {
        dispatch(requestOtherPropertyDetails());
        authAxios
            .put(`${UPDATE_OTHER_PROPERTY_DETAILS}/${id}`,values)
            .then((res) => {
                const otherPropertyDetails=res.data
                dispatch(successOtherPropertyDetails({ otherPropertyDetails }));
                sucsessFeedback()
            })
            .catch((error) => {

                
                dispatch(errorOtherPropertyDetails(error));
            });
    };
};
