import { authAxios } from 'config/axios';
import { ADD_NEW_PROPERTY_TYPE, DELETE_PROPERTY_TYPE, GET_ALL_PROPERTY_TYPES, UPDATE_PROPERTY_TYPE } from 'env/server';
import * as actionTypes from './types';
export const successPropertyTye = (res) => {
    return {
        type: actionTypes.SUCCESS_PROPERTY_TYPE,
        payload: res
    };
};

export const requestPropertyTye = () => {
    return {
        type: actionTypes.REQUST_PROPERTY_TYPE
    };
};
export const errorPropertyTye = (error) => {
    

    // const message = getErrorMessage(error);
    const message = 'There is error';
    return {
        type: actionTypes.FAILED_PROPERTY_TYPE,
        payload: message
    };
};
export const getPropertyTye = () => {
    return (dispatch) => {
        dispatch(requestPropertyTye());
        authAxios
            .get(GET_ALL_PROPERTY_TYPES)
            .then((res) => {
                
                const propertyTypes=res.data
                dispatch(successPropertyTye({propertyTypes}))
            })
            .catch((error) => {
                dispatch(errorPropertyTye(error));

            });
    };
};
export const addNewPropertyTye = (values,feadBacke) => {
    return (dispatch) => {
        dispatch(requestPropertyTye());
        authAxios
            .post(ADD_NEW_PROPERTY_TYPE,values)
            .then((res) => {
                const propertyTypes=res.data
                dispatch(successPropertyTye({propertyTypes}))
                feadBacke()
            })
            .catch((error) => {
                dispatch(errorPropertyTye(error));

            });
    };
};

export const deletePropertyTye = (id) => {
    return (dispatch) => {
        dispatch(requestPropertyTye());
        authAxios
            .delete(`${DELETE_PROPERTY_TYPE}/${id}`)
            .then((res) => {
                const propertyTypes=res.data
                dispatch(successPropertyTye({ propertyTypes }));
            })
            .catch((error) => {
                
                dispatch(errorPropertyTye(error));
            });
    };
};
export const editPropertyTye= (id,values,sucsessFeedback) => {
    return (dispatch) => {
        dispatch(requestPropertyTye());
        authAxios
            .put(`${UPDATE_PROPERTY_TYPE}/${id}`,values)
            .then((res) => {
                const propertyTypes=res.data
                dispatch(successPropertyTye({ propertyTypes }));
                sucsessFeedback()
            })
            .catch((error) => {

                
                dispatch(errorPropertyTye(error));
            });
    };
};
