// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconUsers, IconUserPlus } from '@tabler/icons';
import PRIVILEGES from 'env/privileges';
// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'Account',
    title: 'Account',
    caption: 'Accounts Management',
    privilege: [PRIVILEGES.ACCOUNT_MANAGEMENT],
    type: 'group',
    children: [
        {
            id: 'all-users',
            title: 'All Accounts',
            type: 'item',
            url: '/Users',
            privilege: [PRIVILEGES.ACCOUNT_MANAGEMENT],

            icon: IconUsers,
            breadcrumbs: false
        },
        {
            id: 'add-user',
            title: 'Add New Account',
            type: 'item',
            url: '/Users/add',
            icon: IconUserPlus,
            privilege: [PRIVILEGES.ACCOUNT_MANAGEMENT],

            breadcrumbs: false
        }
    ]
};

export default utilities;
