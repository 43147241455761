import * as actionTypes from './types';

const initialState = {
    loading: false,
    error: false,
    currencies: [],
    supported_currencies: []
};
const currencyReducer = (state = { ...initialState }, action) => {
    const payload = action.payload;
    switch (action.type) {
        case actionTypes.TOGGLE_IS_ACTIVE_CURRENCY_REQUEST:
        case actionTypes.DELETE_CURRENCY_REQUEST:
        case actionTypes.UPDATE_CURRENCY_REQUEST:
        case actionTypes.CREATE_NEW_CURRENCY_REQUEST:
        case actionTypes.GET_ALL_CURRENCIES_REQUEST: {
            return {
                ...state,
                error: null,
                loading: true
            };
        }
        case actionTypes.TOGGLE_IS_ACTIVE_CURRENCY_SUCCESS:
        case actionTypes.DELETE_CURRENCY_SUCCESS:
        case actionTypes.UPDATE_CURRENCY_SUCCESS:
        case actionTypes.CREATE_NEW_CURRENCY_SUCCESS:
        case actionTypes.GET_ALL_CURRENCIES_SUCCESS: {
            return {
                ...state,
                currencies: payload,
                loading: false,
                error: false
            };
        }
        case actionTypes.TOGGLE_IS_ACTIVE_CURRENCY_FAILED:
        case actionTypes.DELETE_CURRENCY_FAILED:
        case actionTypes.UPDATE_CURRENCY_FAILED:
        case actionTypes.CREATE_NEW_CURRENCY_FAILED:
        case actionTypes.GET_ALL_CURRENCIES_FAILED: {
            return {
                ...state,
                loading: false,
                error: payload
            };
        }

        case actionTypes.GET_ALL_SUPPORTED_CURRENCIES_REQUEST: {
            return {
                ...state,
                error: null,
                loading: true
            };
        }
        case actionTypes.GET_ALL_SUPPORTED_CURRENCIES_SUCCESS: {
            return {
                ...state,
                supported_currencies: payload,
                loading: false,
                error: false
            };
        }
        case actionTypes.GET_ALL_SUPPORTED_CURRENCIES_FAILED: {
            return {
                ...state,
                loading: false,
                error: payload
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
};

export default currencyReducer;
