import { authAxios } from 'config/axios';
import { ADD_NEW_PROPERTY_STATUS, DELETE_PROPERTY_STATUS, GET_ALL_PROPERTY_STATUS, UPDATE_PROPERTY_STATUS } from 'env/server';
import * as actionTypes from './types';
export const successPropertyStatus = (res) => {
    return {
        type: actionTypes.SUCCESS_PROPERTY_STATUS,
        payload: res
    };
};

export const requestPropertyStatus = () => {
    return {
        type: actionTypes.REQUST_PROPERTY_STATUS
    };
};
export const errorPropertyStatus = (error) => {
    

    // const message = getErrorMessage(error);
    const message = 'There is error';
    return {
        type: actionTypes.FAILED_PROPERTY_STATUS,
        payload: message
    };
};
export const getPropertyStatus = () => {
    return (dispatch) => {
        dispatch(requestPropertyStatus());
        authAxios
            .get(GET_ALL_PROPERTY_STATUS)
            .then((res) => {
                
                const propertyStatus=res.data
                dispatch(successPropertyStatus({propertyStatus}))
            })
            .catch((error) => {
                dispatch(errorPropertyStatus(error));

            });
    };
};
export const addNewPropertyStatus = (values,feadBacke) => {
    return (dispatch) => {
        dispatch(requestPropertyStatus());
        authAxios
            .post(ADD_NEW_PROPERTY_STATUS,values)
            .then((res) => {
                const propertyStatus=res.data
                dispatch(successPropertyStatus({propertyStatus}))
                feadBacke()
            })
            .catch((error) => {
                dispatch(errorPropertyStatus(error));

            });
    };
};

export const deletePropertyStatus = (id) => {
    return (dispatch) => {
        dispatch(requestPropertyStatus());
        authAxios
            .delete(`${DELETE_PROPERTY_STATUS}/${id}`)
            .then((res) => {
                const propertyStatus=res.data
                dispatch(successPropertyStatus({ propertyStatus }));
            })
            .catch((error) => {
                
                dispatch(errorPropertyStatus(error));
            });
    };
};
export const editPropertyStatus= (id,values,sucsessFeedback) => {
    return (dispatch) => {
        dispatch(requestPropertyStatus());
        authAxios
            .put(`${UPDATE_PROPERTY_STATUS}/${id}`,values)
            .then((res) => {
                const propertyStatus=res.data
                dispatch(successPropertyStatus({ propertyStatus }));
                sucsessFeedback()
            })
            .catch((error) => {

                
                dispatch(errorPropertyStatus(error));
            });
    };
};
