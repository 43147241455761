import { combineReducers } from 'redux';
import accountsMnamentReducer from './AccountsMangment/accountsMnamentReducer';
import authReducer from './Auth/authReducer';
import roleReducer from './Role/roleReducer';
import customizationReducer from './Theme/customizationReducer';
import languagesReducer from './Languges/langugesReducer';
import staticReducer from './static/staticReducer';
import propertyTypeReducer from './propertyType/propertyTypeReducer';
import propertyStatusReducer from './propertyStatus/propertyStatusReducer';
import otherPropertyDetailsReducer from './otherPropertyDetail/otherPropertyDetailsReducer';
import propertyFeatureReducer from './propertyFeature/propertyFeatureReducer';
import propertyTagsReducer from './propertyTags/propertyStatusReducer';
import countryReducer from './countryMangment/countryReducer';
import cityReducer from './cityMangment/cityReducer';
import districtReducer from './districtMangment/districtReducer';
import propertyReducer from './property/propertyReducer';
import contentReducer from './contentManagemet/contentReducer';
import serviceReducer from './serviceManagemet/serviceReducer';
import imageSliderReducer from './imageSliderManagemet/imageSliderReducer';
import settingReducer from './settings/settingReducer';
import currencyReducer from './currencyManagemet/currencyReducer';
import messagesReducer from './messagesManagemet/messagesReducer';

const root = combineReducers({
    auth: authReducer,
    customization: customizationReducer,
    mangmentAccounts: accountsMnamentReducer,
    roleMangment: roleReducer,
    languageseMangment: languagesReducer,
    static: staticReducer,
    property: propertyReducer,

    propertyType: propertyTypeReducer,
    propertyStatus: propertyStatusReducer,
    otherPropertyDetails: otherPropertyDetailsReducer,
    propertyFeatures: propertyFeatureReducer,
    propertyTags: propertyTagsReducer,
    country: countryReducer,
    city: cityReducer,
    district: districtReducer,
    content: contentReducer,
    service: serviceReducer,
    image_slider: imageSliderReducer,
    setting: settingReducer,
    currency: currencyReducer,
    messages: messagesReducer
});
export default root;
