import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import Languages from './languages';
import property from './property';
import Country from './country_city_districts';
import WebsiteContent from './website_content';
import Currencies from './currencies';
import Messages from './messages';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, property, Languages, Currencies, pages, utilities, Country, WebsiteContent, other]
};

export default menuItems;
