import { authAxios } from 'config/axios';
import {
    CREATE_NEW_DISTRICT_END_POINT,
    DELETE_DISTRICT_END_POINT,
    GET_ALL_DISTRICTS_IN_CITY_END_POINT,
    UPDATE_DISTRICT_END_POINT
} from 'env/server';
import * as actionTypes from './types';

export const getAllDistrictsRequest = () => {
    return {
        type: actionTypes.GET_ALL_DISTRICTS_IN_CITY_REQUEST
    };
};
export const getAllDistrictsSuccess = (res) => {
    return {
        type: actionTypes.GET_ALL_DISTRICTS_IN_CITY_SUCCESS,
        payload: res
    };
};
export const getAllDistrictsFailed = (error) => {
    
    const message = 'There is error';
    return {
        type: actionTypes.GET_ALL_DISTRICTS_IN_CITY_FAILED,
        payload: message
    };
};
export const getAllDistricts = (city_id) => {
    return (dispatch) => {
        dispatch(getAllDistrictsRequest());
        authAxios
            .get(GET_ALL_DISTRICTS_IN_CITY_END_POINT + city_id)
            .then((res) => {
                const districts = res.data;
                dispatch(getAllDistrictsSuccess(districts));
            })
            .catch((error) => {
                dispatch(getAllDistrictsFailed(error));
            });
    };
};

export const createNewDistrictRequest = () => {
    return {
        type: actionTypes.CREATE_NEW_DISTRICT_REQUEST
    };
};
export const createNewDistrictSuccess = (res) => {
    return {
        type: actionTypes.CREATE_NEW_DISTRICT_SUCCESS,
        payload: res
    };
};
export const createNewDistrictFailed = (error) => {
    
    const message = 'There is error';
    return {
        type: actionTypes.CREATE_NEW_DISTRICT_FAILED,
        payload: message
    };
};
export const createNewDistrict = (body, onSuccess) => {
    return (dispatch) => {
        dispatch(createNewDistrictRequest());
        authAxios
            .post(CREATE_NEW_DISTRICT_END_POINT, body)
            .then((res) => {
                const districts = res.data;
                dispatch(createNewDistrictSuccess(districts));
                onSuccess();
            })
            .catch((error) => {
                dispatch(createNewDistrictFailed(error));
            });
    };
};

export const updateDistrictRequest = () => {
    return {
        type: actionTypes.UPDATE_DISTRICT_REQUEST
    };
};
export const updateDistrictSuccess = (res) => {
    return {
        type: actionTypes.UPDATE_DISTRICT_SUCCESS,
        payload: res
    };
};
export const updateDistrictFailed = (error) => {
    
    const message = 'There is error';
    return {
        type: actionTypes.UPDATE_DISTRICT_FAILED,
        payload: message
    };
};
export const updateDistrict = (district_id, body, onSuccess) => {
    return (dispatch) => {
        dispatch(updateDistrictRequest());
        authAxios
            .put(UPDATE_DISTRICT_END_POINT + district_id, body)
            .then((res) => {
                const districts = res.data;
                dispatch(updateDistrictSuccess(districts));
                onSuccess();
            })
            .catch((error) => {
                dispatch(updateDistrictFailed(error));
            });
    };
};

export const deleteDistrictRequest = () => {
    return {
        type: actionTypes.DELETE_DISTRICT_REQUEST
    };
};
export const deleteDistrictSuccess = (res) => {
    return {
        type: actionTypes.DELETE_DISTRICT_SUCCESS,
        payload: res
    };
};
export const deleteDistrictFailed = (error) => {
    
    const message = 'There is error';
    return {
        type: actionTypes.DELETE_DISTRICT_FAILED,
        payload: message
    };
};
export const deleteDistrict = (district_id, onSuccess) => {
    return (dispatch) => {
        dispatch(deleteDistrictRequest());
        authAxios
            .delete(DELETE_DISTRICT_END_POINT + district_id)
            .then((res) => {
                const districts = res.data;
                dispatch(deleteDistrictSuccess(districts));
                onSuccess();
            })
            .catch((error) => {
                dispatch(deleteDistrictFailed(error));
            });
    };
};
