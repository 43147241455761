const PRIVILEGES = {
    ACCOUNT_MANAGEMENT: 'account_management',
    LANGUAGE_MANAGEMENT: 'language_management',
    ROLE_MANAGEMENT: 'role_management',
    SALES_MANAGEMENT: 'sales_management',
    SERVICE_MANAGEMENT: 'service_management',
    SETTINGS_MANAGEMENT: 'settings_management',
    CURRENCY_MANAGEMENT: 'currency_management',
    COUNTRY_STATES_DISTRICT_MANAGEMENT: 'country_states_district_management',
    ABOUT_US_MANAGEMENT: 'about_us_management',
    CONTENT_MANAGEMENT: 'content_management',
    IMAGE_SLIDER_MANAGEMENT: 'image_slider_management',
    OTHER_DETAIL_MANAGEMENT: 'other_detail_management',
    OTHER_PROPERTY_DETAILS_MANAGEMENT: 'other_property_details_management',
    PROPERTY_STATUS_MANAGEMENT: 'property_status_management',
    PROPERTY_TYPE_MANAGEMENT: 'property_type_management',
    PROPERTY_TAGS_MANAGEMENT: 'tag_management',
    PROPERTY_FEATURES_MANAGEMENT: 'feature_management',
    PAYMENT_PLAN_TYPE_MANAGEMENT: 'payment_plan_type_management',
    PROPERTY_KITCHEN_TYPE_MANAGEMENT: 'property_kitchen_type_management',
    MAKER: 'maker',
    COPYWRITER: 'copywriter',
    CHECKER: 'checker'
};

export default PRIVILEGES;
