import * as actionTypes from './types';

const inituelState = {
    loading: false,
    error: false,
    
    propertyFeatures: null
};
const propertyFeatureReducer = (state = { ...inituelState }, action) => {
    const payload = action.payload;
    switch (action.type) {
        case actionTypes.REQUST_PROPERTY_FEATURE:
            return {
                ...state,
                loading: true
            };
        case actionTypes.FAILED_PROPERTY_FEATURE:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case actionTypes.SUCCESS_PROPERTY_FEATURE:
            return {
                ...state,
                ...payload,
                loading: false,
                error: false
            };

      
        default:
            return {
                ...state
            };
    }
};

export default propertyFeatureReducer;
