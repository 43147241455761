// assets
import { IconBusinessplan } from '@tabler/icons';
import PRIVILEGES from 'env/privileges';

// constant
const icons = {
    IconBusinessplan
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const Currencies = {
    id: 'Currencies',
    title: 'Currencies',
    caption: 'Currencies Management',
    type: 'group',
    privilege: [PRIVILEGES.CURRENCY_MANAGEMENT],
    children: [
        {
            id: 'AllCurrencies',
            title: 'All Currencies',
            type: 'item',
            icon: icons.IconBusinessplan,
            privilege: [PRIVILEGES.CURRENCY_MANAGEMENT],
            url: '/currencies'
        },
        {
            id: 'AddCurrency',
            title: 'Add New Currency',
            type: 'item',
            icon: () => {
                return <i className="fa-solid fa-coins"></i>;
            },
            privilege: [PRIVILEGES.CURRENCY_MANAGEMENT],
            url: '/currencies/add'
        }
    ]
};

export default Currencies;
