import { authAxios } from 'config/axios';
import { ADD_PROPERTY_TAGS, DELETE_PROPERTY_TAGS, GET_ALL_PROPERTY_TAGS, UPDATE_PROPERTY_TAGS } from 'env/server';
import * as actionTypes from './types';
export const successPropertyTags = (res) => {
    return {
        type: actionTypes.SUCCESS_PROPERTY_TAGS,
        payload: res
    };
};

export const requestPropertyTags = () => {
    return {
        type: actionTypes.REQUST_PROPERTY_TAGS
    };
};
export const errorPropertyTags = (error) => {
    

    // const message = getErrorMessage(error);
    const message = 'There is error';
    return {
        type: actionTypes.FAILED_PROPERTY_TAGS,
        payload: message
    };
};
export const getPropertyTags = () => {
    return (dispatch) => {
        dispatch(requestPropertyTags());
        authAxios
            .get(GET_ALL_PROPERTY_TAGS)
            .then((res) => {
                
                const propertyTags=res.data
                dispatch(successPropertyTags({propertyTags}))
            })
            .catch((error) => {
                dispatch(errorPropertyTags(error));

            });
    };
};
export const addNewPropertyTags = (values,feadBacke) => {
    return (dispatch) => {
        dispatch(requestPropertyTags());
        authAxios
            .post(ADD_PROPERTY_TAGS,values)
            .then((res) => {
                const propertyTags=res.data
                dispatch(successPropertyTags({propertyTags}))
                feadBacke()
            })
            .catch((error) => {
                dispatch(errorPropertyTags(error));

            });
    };
};

export const deletePropertyTags = (id) => {
    return (dispatch) => {
        dispatch(requestPropertyTags());
        authAxios
            .delete(`${DELETE_PROPERTY_TAGS}/${id}`)
            .then((res) => {
                const propertyTags=res.data
                dispatch(successPropertyTags({ propertyTags }));
            })
            .catch((error) => {
                
                dispatch(errorPropertyTags(error));
            });
    };
};
export const editPropertyTags= (id,values,sucsessFeedback) => {
    return (dispatch) => {
        dispatch(requestPropertyTags());
        authAxios
            .put(`${UPDATE_PROPERTY_TAGS}/${id}`,values)
            .then((res) => {
                const propertyTags=res.data
                dispatch(successPropertyTags({ propertyTags }));
                sucsessFeedback()
            })
            .catch((error) => {

                
                dispatch(errorPropertyTags(error));
            });
    };
};
