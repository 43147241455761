// assets
import { IconKey } from '@tabler/icons';
import PRIVILEGES from 'env/privileges';
// constant
const icons = {
    IconKey
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'Role',
    title: 'Role',
    caption: 'Roles Management',
    type: 'group',
    privilege: [PRIVILEGES.ROLE_MANAGEMENT],
    children: [
        {
            id: 'All_Role',
            title: 'All Role',
            type: 'item',
            icon: () => {
                return <i className="fa-solid fa-list-check"></i>;
            },
            privilege: [PRIVILEGES.ROLE_MANAGEMENT],
            url: '/Roles'
        },
        {
            id: 'Add-New-Role',
            title: 'Add New Role',
            type: 'item',
            icon: icons.IconKey,
            privilege: [PRIVILEGES.ROLE_MANAGEMENT],
            url: '/Roles/add'
        }
    ]
};

export default pages;
