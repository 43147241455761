import * as actionTypes from './types';

const initialState = {
    loading: false,
    error: false,
    districts: []
};
const districtReducer = (state = { ...initialState }, action) => {
    const payload = action.payload;
    switch (action.type) {
        case actionTypes.DELETE_DISTRICT_REQUEST:
        case actionTypes.UPDATE_DISTRICT_REQUEST:
        case actionTypes.CREATE_NEW_DISTRICT_REQUEST:
        case actionTypes.GET_ALL_DISTRICTS_IN_CITY_REQUEST: {
            return {
                ...state,
                error: null,
                loading: true
            };
        }
        case actionTypes.DELETE_DISTRICT_SUCCESS:
        case actionTypes.UPDATE_DISTRICT_SUCCESS:
        case actionTypes.CREATE_NEW_DISTRICT_SUCCESS:
        case actionTypes.GET_ALL_DISTRICTS_IN_CITY_SUCCESS: {
            return {
                ...state,
                districts: payload,
                loading: false,
                error: false
            };
        }
        case actionTypes.DELETE_DISTRICT_FAILED:
        case actionTypes.UPDATE_DISTRICT_FAILED:
        case actionTypes.CREATE_NEW_DISTRICT_FAILED:
        case actionTypes.GET_ALL_DISTRICTS_IN_CITY_FAILED: {
            return {
                ...state,
                loading: false,
                error: payload
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
};

export default districtReducer;
