import * as actionTypes from './types';

const inituelState = {
    loading: false,
    error: false,
    AllProperties: null,
    publishedProperties:null,

    needCopyWriteProperties:null,
    needRemakeProperties:null,
    needCheckeProperties:null,

};
const propertyReducer = (state = { ...inituelState }, action) => {
    const payload = action.payload;
    switch (action.type) {
        case actionTypes.REQUST_PROPERTY:
            return {
                ...state,
                loading: true,
                error:null
            };
        case actionTypes.FAILED_PROPERTY:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case actionTypes.SUCCESS_PROPERTY:
            return {
                ...state,
                ...payload,
                loading: false,
                error: false
            };

        default:
            return {
                ...state
            };
    }
};

export default propertyReducer;
