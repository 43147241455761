import { authAxios } from 'config/axios';
import { ADD_COUNTRY, DELETE_COUNTRY, GET_ALL_COUNTRY, UPDATE_COUNTRY } from 'env/server';
import * as actionTypes from './types';
export const successCountry = (res) => {
    return {
        type: actionTypes.SUCCESS_COUNTRY,
        payload: res
    };
};

export const requestCountry = () => {
    return {
        type: actionTypes.REQUST_COUNTRY
    };
};
export const errorCountry = (error) => {
    

    // const message = getErrorMessage(error);
    const message = 'There is error';
    return {
        type: actionTypes.FAILED_COUNTRY,
        payload: message
    };
};
export const getCountry = () => {
    return (dispatch) => {
        dispatch(requestCountry());
        authAxios
            .get(GET_ALL_COUNTRY)
            .then((res) => {
                
                const countries=res.data
                dispatch(successCountry({countries}))
            })
            .catch((error) => {
                dispatch(errorCountry(error));

            });
    };
};
export const addNewCountry = (values,feadBacke) => {
    return (dispatch) => {
        dispatch(requestCountry());
        authAxios
            .post(ADD_COUNTRY,values)
            .then((res) => {
                const countries=res.data
                dispatch(successCountry({countries}))
                feadBacke()
            })
            .catch((error) => {
                dispatch(errorCountry(error));

            });
    };
};

export const deleteCountry = (id) => {
    return (dispatch) => {
        dispatch(requestCountry());
        authAxios
            .delete(`${DELETE_COUNTRY}/${id}`)
            .then((res) => {
                const countries=res.data
                dispatch(successCountry({ countries }));
            })
            .catch((error) => {
                
                dispatch(errorCountry(error));
            });
    };
};
export const editCountry= (id,values,sucsessFeedback) => {
    return (dispatch) => {
        dispatch(requestCountry());
        authAxios
            .put(`${UPDATE_COUNTRY}/${id}`,values)
            .then((res) => {
                const countries=res.data
                dispatch(successCountry({ countries }));
                sucsessFeedback()
            })
            .catch((error) => {

                
                dispatch(errorCountry(error));
            });
    };
};
